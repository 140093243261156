import { initializeApp } from 'firebase/app';
import { 
  getAuth, 
  GoogleAuthProvider,
  setPersistence,
  browserLocalPersistence,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword
} from 'firebase/auth';
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAnalytics, isSupported } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyD4NymPrj43fO3zngu6wcgc-aWR3epoHow",
  authDomain: "bunny-8add2.firebaseapp.com",
  projectId: "bunny-8add2",
  storageBucket: "bunny-8add2.firebasestorage.app",
  messagingSenderId: "104418625412",
  appId: "1:104418625412:web:99eee4f5432ce43f467fd1",
  measurementId: "G-DEC468M928"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize services
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const googleProvider = new GoogleAuthProvider();

// Set persistence
setPersistence(auth, browserLocalPersistence).catch(console.error);

// Initialize Analytics conditionally
let analytics = null;
isSupported().then(supported => {
  if (supported) {
    analytics = getAnalytics(app);
  }
}).catch(console.error);

export const initializeAdminAccount = async () => {
  try {
    // Check if admin account exists
    const adminEmail = 'admin@example.com';
    const adminPassword = 'Password';

    try {
      // Try to sign in first
      await signInWithEmailAndPassword(auth, adminEmail, adminPassword);
    } catch (error) {
      // If sign in fails, create the account
      const { user } = await createUserWithEmailAndPassword(auth, adminEmail, adminPassword);
      
      // Create admin user document
      await setDoc(doc(db, 'users', user.uid), {
        email: adminEmail,
        displayName: 'Admin',
        role: 'admin',
        createdAt: new Date(),
        status: {
          active: true,
          approved: true
        }
      });
    }

    // Sign out after initialization
    await auth.signOut();
  } catch (error) {
    console.error('Error initializing admin account:', error);
  }
};

export { analytics };